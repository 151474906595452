/*=============================================
=            Variable declarations          =
=============================================*/
const menuBtn = document.querySelector(".menu-btn");
const navMenu = document.querySelector(".home-nav");
const btnLine = document.querySelectorAll(".btn-line");
const navBtn = document.querySelectorAll(".nav-btn");
const secondardNavBtn = document.querySelectorAll(".secondary-nav-btn");
const container = document.querySelector(".container");
const scrollIcon = document.querySelector(".home-scroll");
let currentState = menuBtn.getAttribute("data-state") ?? "closed";


/*=============================================
=            Event handler Nav Menu          =
=============================================*/

navMenu.addEventListener("transitionend", () => {
  console.log(currentState);
  if (currentState === "closed") {
    console.log("ran");
    container.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }
});

const toggleMenu = () => {
  currentState = menuBtn.getAttribute("data-state") ?? "closed";
  const newState = currentState === "closed" ? "opened" : "closed";

  menuBtn.setAttribute("data-state", newState);
  menuBtn.setAttribute("aria-expanded", newState === "opened");
  navMenu.setAttribute("data-state", newState);

};

menuBtn.addEventListener("click", toggleMenu);
navBtn.forEach((btn) => {
  btn.addEventListener("click", toggleMenu);
});

/*=============================================
= Intersection Observer for onView animations =
=============================================*/
const hiddenElements = document.querySelectorAll(".hidden");

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      scrollIcon.classList.add("hidden");
      entry.target.classList.add("show");
    } else {
      entry.target.classList.remove("show");
      // scrollIcon.classList.remove("hidden");
    }
  });
});

hiddenElements.forEach((el) => observer.observe(el));

/*=============================================
=        Scroll to element function           =
=============================================*/
function scrollToEl(id) {
  var element = document.getElementById(id);
  element.scrollIntoView({
    behavior: "smooth",
  });
}

/*=============================================
=               Send Email                   =
=============================================*/



function sendEmail(){

var nameOfSender = document.getElementById("name").value;
var emailOfSender = document.getElementById("email").value;
var messageOfSender = document.getElementById("message").value;

// Format email body with HTML
var emailBody = `
  <html>
    <head>
      <style>
        /* Add your CSS styles here */
      </style>
    </head>
    <body>
      <h3>New Enquiry</h3>
      <p><strong>Name:</strong> ${nameOfSender}</p>
      <p><strong>Email:</strong> ${emailOfSender}</p>
      <p><strong>Message:</strong></p>
      <p>${messageOfSender}</p>
    </body>
  </html>
`;



  Email.send({
    SecureToken: "f14face0-a6bd-4580-b95a-351770601c96",
    To: "suretymanagementsolutions@gmail.com",
    From: "suretymanagementsolutions@gmail.com",
    Subject: "New Enquiry",
    Body: emailBody
    
  }).then(message => alert(message))
}

function redirectToPrivacyPolicy(){
  console.log("hello")
  window.location.href = 'privacy-policy.html';
}